module.exports = {
  intro: {
    title1: "Introducing Pay with Points",
    title2: "by AYANA Rewards",
    subtitle:
      "Turn your AYANA Rewards Points into Unforgettable Experiences at AYANA Hotels",
    cta: "CHECK RATES",
  },
  title: "リバーサイド ゴルフクラブでアヤナリワードポイントを獲得しましょう",
  pros: {
    first: {
      title: "ラウンドごとにポイントを獲得してリワードを受け取りましょう",
      description:
        "2024年5月から、リバーサイドゴルフクラブのグリーンフィーにIDR 15,000を使用するごとに、1 アヤナリワードポイントを獲得できます。",
    },
    second: {
      title: "アヤナリワードの限定特典を受け取りましょう",
      description:
        "アヤナリワード会員として、お客様のポイントはゴルフティータイムの割引、お部屋のアップグレード、アーリーチェックイン、レイトチェックアウトなどを含む様々な特典へとお使いいただけます。",
      tierLevel: "*ティアレベルによります。",
    },
  },
  preferred_dest: {
    title: "ポイントを利用して、お客様のアヤナ エスケープをお選びください。",
    subtitle:
      "インドネシア各地にあるアヤナホテルで忘れられない体験が待っています！アヤナリワードポイントを使って、ラクジェリーエスケープや極上の楽園でのひとときをお楽しみください。",
    bali: "バリ",
    jakarta: "ジャカルタ主要エリア",
    komodo: "コモド",
    DXKR: "デロニックス ホテル カラワンに滞在",
    JKTHB: "アヤナ ミッドプラザ ジャカルタ に滞在",
    SEGARA: "AYANA Segara Bali",
    hotelStay: "ホテルステイ",
    giftVoucher: "ギフトバウチャー",
    krisFlyerMiles: "クリスフライヤーマイル",
    diningVoucher: "ダイニングバウチャー",
  },
  how_it_works: {
    title:
      "特典とリワードを受け取りましょう：リバーサイドゴルフクラブのアヤナリワード",
    subtitle: "Here's how it works:",
    first: "会員限定の特典でより快適な滞在を。今すぐサインアップ！",
    second:
      "リバーサイドの年間会員権をご購入いただくと、アヤナリワードゴールドに即アップグレードされます。（規約適用)",
    third:
      "長期リバーサイド会員権をご購入いただくと、アヤナリワードプラチナムに即アップグレードされます。（規約適用）",
    fourth:
      "ゴールドとプラチナのメンバーはご使用金額が一定に達した場合、ボーナスポイントを獲得できます。（規約適用）",
  },
  find_hotels: {
    title: "アヤナリワードメンバーになって、限定特典をお楽しみください。",
    cta: "今すぐサインアップ！",
  },
  faq: {
    title: "よくある質問",
    first: {
      question:
        "リバーサイド ゴルフクラブでティータイムを予約する方法を教えてください。",
      answer:
        "リバーサイド ゴルフクラブのウェブサイト からオンラインでご予約いただけます。",
      link: "https://riverside-gc.com/",
    },
    second: {
      question:
        "年間または長期のリバーサイド会員権を購入した場合、アヤナリワードポイントは付与されますか？",
      answer:
        "いいえ、購入することではポイントは付与されません。ただし、アヤナリワードのランクは即座にアップグレードされます。",
    },
    third: {
      question:
        "アヤナリワードのポイントの付与がアカウントに反映されるまでにどのくらいかかりますか？",
      answer:
        "アヤナリワードのポイント付与には通常、7営業日かかります。7営業日を過ぎてもポイントが反映されていない場合は、AYANAリワード（info@ayanarewards.com） まで直接お問い合わせください。",
    },
  },
  tnc: "Terms & Conditions",
};
